<template>
  <div
    :class="`${
      $store.getters['display/getAside'] ? 'contents expanded' : 'contents'
    }`"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <Breadcrumb :options="breadcrumbOptions" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 mb-30">
          <div class="card">
            <div class="my-card-header color-dark fw-500 pt-3">
              <span class="float-left py-2">
                <span class="h4 px-3">
                  {{ t("filter", {}, { locale: getLocal }) }}
                </span>
              </span>
              <span class="float-right">
                <!-- <span class="mailbox-top__search search m-1  " >
                                        <div class="input-container float-right position-relative ml-3 max-w-px-200">
                                            <span class="input-icon icon-left">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                            </span>
                                            <input v-on:keyup.enter="startSearch" v-model="drawNumber" type="text" class="w-100 form-control-lg color-gold"  placeholder="drawNumber">
                                        </div>
                                    </span>
                                    <span class="mailbox-top__search search m-1 " >
                                        <div class="input-container float-right position-relative ml-3 max-w-px-200">
                                            <span class="input-icon icon-left">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                            </span>
                                            <input v-on:keyup.enter="startSearch" v-model="drawDate" type="date" class="w-100 form-control-lg color-gold" placeholder="drawDate">
                                        </div>
                                    </span> -->
                <span class="max-w-px-200 my-dropdown">
                  <label for="Order">{{
                    t("orderby", {}, { locale: getLocal })
                  }}</label>
                  <select v-model="order" name="Order" class="max-w-px-200">
                    <option value="createdAt">
                      {{ t("creat", {}, { locale: getLocal }) }}
                    </option>
                    <option value="drawNumber">
                      {{ t("Dnumber", {}, { locale: getLocal }) }}
                    </option>
                    <option value="drawDate">
                      {{ t("Ddate", {}, { locale: getLocal }) }}
                    </option>
                  </select>
                </span>
                <span class="max-w-px-200 my-dropdown">
                  <label for="dir">{{
                    t("dir", {}, { locale: getLocal })
                  }}</label>
                  <select v-model="dir" name="dir" class="max-w-px-200">
                    <option value="ASC">
                      {{ t("asc", {}, { locale: getLocal }) }}
                    </option>
                    <option value="DESC">
                      {{ t("desc", {}, { locale: getLocal }) }}
                    </option>
                  </select>
                </span>
                <span class="max-w-px-200 my-dropdown">
                  <label for="status"
                    >{{ t("status", {}, { locale: getLocal }) }}
                  </label>
                  <select v-model="status" name="status" class="max-w-px-200">
                    <option value="">
                      {{ t("all", {}, { locale: getLocal }) }}
                    </option>
                    <option value="staging">
                      {{ t("staging", {}, { locale: getLocal }) }}
                    </option>
                    <option value="on_going">
                      {{ t("ongoing", {}, { locale: getLocal }) }}
                    </option>
                    <option value="finish">
                      {{ t("finish", {}, { locale: getLocal }) }}
                    </option>
                  </select>
                </span>
                <span class="max-w-px-200 my-dropdown">
                  <label for="isLuckyNumber">{{
                    t("islucky", {}, { locale: getLocal })
                  }}</label>
                  <select
                    v-model="isLuckyNumber"
                    name="isLuckyNumber"
                    class="max-w-px-200"
                  >
                    <option value="N">
                      {{ t("no", {}, { locale: getLocal }) }}
                    </option>
                    <option value="Y">
                      {{ t("yes", {}, { locale: getLocal }) }}
                    </option>
                  </select>
                </span>
                <span class="max-w-px-200 my-dropdown">
                  <label for="drawDate"
                    >{{ t("drawdate", {}, { locale: getLocal }) }}
                  </label>
                  <input v-model="drawDate" type="date" />
                </span>
                <span class="max-w-px-200 my-dropdown">
                  <label for="drawNumber">{{
                    t("drawnumber", {}, { locale: getLocal })
                  }}</label>
                  <input
                    v-model="drawNumber"
                    type="text"
                    :placeholder="t('drawnumber', {}, { locale: getLocal })"
                  />
                </span>
              </span>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div
                    class="row border border-warning rounded m-3 p-3"
                    v-for="(e, index) in listData"
                    :key="index"
                  >
                    <div class="col-12 col-md-6">
                      <img
                        @click="showIMG(e)"
                        v-if="e.image"
                        :src="e.image"
                        alt="img_uploaded"
                        class="w-100"
                      />
                      <div v-else class="text-center">-</div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style="height: 100%"
                      >
                        <div>
                          <p
                            class="
                              h1
                              text-center
                              font-weight-bold
                              textspace
                              mb-3
                            "
                          >
                            {{ e.lottoNumber ? e.lottoNumber : "-" }}
                          </p>
                          <p class="h3 text-center mb-1 text-gray">
                            {{ e.drawDateTh ? e.drawDateTh : "-" }}
                          </p>
                          <p class="h3 text-center mb-1 text-gray">
                            {{ e.drawDateEn ? e.drawDateEn : "-" }}
                          </p>
                          <p class="h3 text-center mb-1 text-gray">
                            {{ e.lottoRefNumber ? e.lottoRefNumber : "-" }}
                          </p>
                          <div class="row">
                            <div class="col-6">
                              <p class="h3 text-center mb-2">
                                {{ e.drawNumber ? e.drawNumber : "-" }}
                              </p>
                            </div>
                            <div class="col-6">
                              <p class="h3 text-center mb-2">
                                {{ e.setNumber ? e.setNumber : "-" }}
                              </p>
                            </div>
                          </div>
                          <p class="h3 text-center mb-1 text-gray">
                            {{
                              e.lottoBarcodeRawData
                                ? e.lottoBarcodeRawData
                                : "-"
                            }}
                          </p>
                          <p class="h3 text-center mb-1 text-gray">
                            {{
                              e.lottoBarcodeTextAppear
                                ? e.lottoBarcodeTextAppear
                                : "-"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style="height: 100%"
                      >
                        <div>
                          <p
                            v-if="e.status == 'staging'"
                            class="h3 text-center font-weight-bold text-yellow"
                          >
                            {{ e.status ? e.status : "-" }}
                          </p>
                          <p
                            v-else-if="e.status == 'on_going'"
                            class="h3 text-center font-weight-bold text-green"
                          >
                            {{ e.status ? e.status : "-" }}
                          </p>
                          <p
                            v-else-if="e.status == 'finish'"
                            class="h3 text-center font-weight-bold text-gray"
                          >
                            {{ e.status ? e.status : "-" }}
                          </p>
                          <p
                            v-else-if="e.status == 'sold'"
                            class="h3 text-center font-weight-bold text-blue"
                          >
                            {{ e.status ? e.status : "-" }}
                          </p>
                          <p v-else class="h3 text-center font-weight-bold">
                            {{ e.status ? e.status : "-" }}
                          </p>
                          <div class="row mt-3">
                            <div class="col-6">
                              <button
                                v-if="$store.getters['auth/getUser']"
                                @click="SwalselectStatusOn(e, 'On Going')"
                                class="btn-action btn-action-close w-100 p-1"
                              >
                                {{ t("ongoing", {}, { locale: getLocal }) }}
                              </button>
                            </div>
                            <div class="col-6">
                              <button
                                v-if="$store.getters['auth/getUser']"
                                @click="SwalselectStatusClose(e, 'Cancel')"
                                class="btn-action btn-action-delete w-100 p-1"
                              >
                                {{ t("cancel", {}, { locale: getLocal }) }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <hr class="my-1" />
                </div>
                <div class="col-12">
                  <PaginationByConfirmLotto
                    ref="PaginationRef"
                    :Count="count"
                    @PaginationReturnData="PaginationReturnData"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalImg :srcImage="showIMGData" />
  </div>
</template>

<script>
// @ is an alias to /src

import * as serviceAPI from "../services/API.service";
import * as serviceMain from "../services/main.service";
import * as constant from "../services/constant";
import PaginationByConfirmLotto from "@/components/PaginationByConfirmLotto.vue";
import { useI18n } from "vue-i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ModalImg from "@/components/Modal/ModalImg.vue";
export default {
  name: "ConfirmLotto",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  components: {
    PaginationByConfirmLotto,
    Breadcrumb,
    ModalImg,
  },
  data: function () {
    return {
      breadcrumbOptions: {
        isShowRefresh: true,
        isShowStartSearch: true,
        pageKey: "lottolist",
        refresh: () => this.Refresh(),
        startSearch: () => this.startSearch(),
      },
      order: "drawNumber",
      dir: "ASC",
      status: "staging",
      drawNumber: "",
      drawDate: "",
      isLuckyNumber: "N",
      onSearch: false,
      count: 0,
      PaginationData: {},

      imgPath: constant.imageUrl,
      selectedOne: "",
      errors: [],
      accessData: [],
      accessDataCustomer: [],
      companyData: [],
      listData: [],
      selectedID: [],
      createData: {},
      editData: {
        lottoId: "",
        lottoNumber: "",
        setNumber: "",
        drawDateTh: "",
        drawDateEn: "",
        drawNumber: "",
        lottoRefNumber: "",
        lottoBarcodeRawData: "",
        lottoBarcodeTextAppear: "",
        price: "",
        image: "",
        IMAGESRC: "",
      },
      showIMGData: "",
    };
  },
  mounted() {
    this.showData();
    // this.getCount();
    // this.getAccessDataCustomer();
  },
  methods: {
    async Refresh() {
      this.count = 0;
      this.onSearch = false;
      this.order = "drawNumber";
      this.dir = "ASC";
      this.status = "staging";
      this.drawNumber = "";
      this.drawDate = "";
      this.isLuckyNumber = "N";
      this.showData();
    },
    async startSearch() {
      this.count = 0;
      this.onSearch = true;
      this.showData();
    },
    PaginationReturnData(para_val) {
      this.PaginationData = para_val;

      this.showData();
    },
    // async getCount(){

    //     try {
    //         let data = {
    //             SEARCH:this.keySearch,
    //             OFFSET:this.PaginationData.offset,
    //             RWS:this.PaginationData.limit
    //         }

    //         let getURL =  this.onSearch ? 'loanagreement/countloanagreementsearch':'loanagreement/countloanagreement'
    //         let getAPI = await serviceAPI.call_API('get',getURL,data,'auth');
    //         this.count = getAPI.data.data[0].count
    //     } catch (error) {
    //         serviceMain.showErrorAlert(this,error)
    //     }
    // },

    async showData() {
      try {
        let data = {
          start: this.PaginationData.offset,
          length: this.PaginationData.limit,
          // start:'0',
          // length:'10',
          order: this.order,
          dir: this.dir,
          status: this.status,
          drawNumber: this.drawNumber,
          drawDate: this.drawDate,
          isLuckyNumber: this.isLuckyNumber,
        };
        let getAPI = await serviceAPI.call_API(
          "get",
          "lotto/api/v1/listLotto",
          data,
          "auth"
        );
        console.log(getAPI);
        this.count = getAPI.data.recordsFiltered;
        this.listData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async showIMG(e) {
      $("#modal-image").modal("show");
      this.showIMGData = e.image;
    },
    async onFileChangeedit(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.editData.image = files[0];
      var reader = new FileReader();

      reader.onload = (e) => {
        this.editData.IMAGESRC = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    },

    async SwalselectStatusOn(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You won't be able to ${para_status} this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${para_status} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatusOn(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your is safe :)",
              "error"
            );
          }
        });
    },
    async SwalselectStatusClose(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You won't be able to ${para_status} this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${para_status} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatusCancel(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your is safe :)",
              "error"
            );
          }
        });
    },

    async selectStatusOn(e) {
      try {
        let data = {
          lottoId: e.id,
        };
        console.log(data);
        let getAPI = await serviceAPI.call_API(
          "post",
          "lotto/api/v1/setLottoStatusToOnGoing",
          data,
          "auth"
        );

        this.Refresh();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async selectStatusCancel(e) {
      try {
        let data = {
          lottoId: e.id,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "lotto/api/v1/setLottoStatusToCancel",
          data,
          "auth"
        );

        this.Refresh();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },

    async SwalDeleteUser(e) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.DeleteUser(e);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalCancel(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Cancel this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Cancel it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalClose(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Close this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Close it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalDelete(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async DeleteUser(e) {
      try {
        // let getFileName = para_img.split("/img/")[1] ;
        // if(!getFileName){
        //     getFileName='';
        // }
        // https://eastest.s3.ap-southeast-1.amazonaws.com/img/8b05a26c-1a3b-42b0-8a78-ae450db98ee7.png
        let data = { LOANID: e.id };
        let getAPI = await serviceAPI.call_API(
          "delete",
          "loanagreement/crudloanagreement",
          data,
          "auth"
        );
        let getData = getAPI.data.data;
        this.Refresh();
        this.$swal.fire("Deleted!", "Your user has been deleted.", "success");
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async getAccessData() {
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API(
          "get",
          "user/getaccessdata",
          data,
          "auth"
        );
        this.accessData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },

    async getAccessDataCustomer() {
      try {
        let data = {
          mode: "all",
          ID: "1",
          OFFSET: "0",
          RWS: "0",
        };
        let getAPI = await serviceAPI.call_API(
          "get",
          "customer/crudcustomer",
          data,
          "auth"
        );
        this.accessDataCustomer = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    // async getCompanyData(){
    //     try {
    //         let data = {}
    //         let getAPI = await serviceAPI.call_API('post','getallcompany',data,'auth');
    //         this.companyData = getAPI.data.data
    //     } catch (error) {
    //         serviceMain.showErrorAlert(this,error)
    //     }
    // },

    async editForm(e) {
      $("#modal-edit").modal("show");
      this.editData = {
        lottoId: e.id,
        lottoNumber: e.lottoNumber,
        setNumber: e.setNumber,
        IMAGESRC: e.image,
        drawDateTh: e.drawDateTh,
        drawDateEn: e.drawDateEn,
        drawNumber: e.drawNumber,
        lottoRefNumber: e.lottoRefNumber,
        lottoBarcodeRawData: e.lottoBarcodeRawData,
        lottoBarcodeTextAppear: e.lottoBarcodeTextAppear,
        price: e.price,
      };
    },

    async sendEditData(e) {
      try {
        e.preventDefault();
        let form = new FormData();
        form.append("lottoId", this.editData.lottoId);
        form.append("lottoNumber", this.editData.lottoNumber);
        form.append("setNumber", this.editData.setNumber);
        form.append("drawDateTh", this.editData.drawDateTh);
        form.append("drawDateEn", this.editData.drawDateEn);
        form.append("drawNumber", this.editData.drawNumber);
        form.append("lottoRefNumber", this.editData.lottoRefNumber);
        form.append("lottoBarcodeRawData", this.editData.lottoBarcodeRawData);
        form.append(
          "lottoBarcodeTextAppear",
          this.editData.lottoBarcodeTextAppear
        );
        form.append("price", this.editData.price);
        form.append("image", this.editData.image);

        let getAPI = await serviceAPI.call_API_FORMDATA(
          "POST",
          "lotto/api/v1/editLotto",
          form
        );

        $("#modal-edit").modal("hide");
        this.editData.lottoId = "";
        this.editData.lottoNumber = "";
        this.editData.IMAGESRC = "";
        this.editData.setNumber = "";
        this.editData.drawDateTh = "";
        this.editData.drawDateEn = "";
        this.editData.drawNumber = "";
        this.editData.lottoRefNumber = "";
        this.editData.lottoBarcodeRawData = "";
        this.editData.lottoBarcodeTextAppear = "";
        this.editData.price = "";
        this.editData.image = "";

        let getData = getAPI.status.messageTH;
        serviceMain.showSuccessAlert(this, getData);
        this.Refresh();
      } catch (error) {
        $("#modal-edit").modal("hide");
        serviceMain.showErrorAlert(this, error);
      }
    },

    async checkForm(e) {
      e.preventDefault();

      if (
        this.createData.ID &&
        this.createData.BANKID &&
        this.createData.BANKNAME &&
        this.createData.BACCNO &&
        this.createData.BACCNA
      ) {
        this.sendCreateData();
        return;
      }

      this.errors = [];

      if (!this.createData.USERNAME) {
        this.errors.push("Username required.");
      }
      if (!this.createData.PASSWORD) {
        this.errors.push("Password required.");
      }
      if (!this.createData.FNAME) {
        this.errors.push("First Name required.");
      }
      if (!this.createData.LNAME) {
        this.errors.push("Last Name required.");
      }
      if (!this.createData.ROLE) {
        this.errors.push("Role required.");
      }
    },
    // uploadImage(e){
    //     let image = e.target.files[0];
    //     let FR= new FileReader();
    //     FR.onload = (ev) => {
    //         let getOringinalImg = ev.target.result;
    //         // this.createData.USER_IMG = ev.target.result;
    //         this.resizedataURL(this,getOringinalImg,100,100)
    //     }
    //     FR.readAsDataURL(image)
    // },
    // resizedataURL(_this,datas, wantedWidth, wantedHeight)
    // {
    //     var img = document.createElement('img');
    //     img.onload = function()
    //         {
    //             var canvas = document.createElement('canvas');
    //             var ctx = canvas.getContext('2d');
    //             canvas.width = wantedWidth;
    //             canvas.height = wantedHeight;
    //             ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
    //             var dataURI = canvas.toDataURL();
    //             console.log("dataURI:")
    //             console.log(dataURI)
    //             _this.createData.USER_IMG = dataURI;
    //         };
    //     img.src = datas;
    // },
    ckbox(para_id) {
      if (this.selectedID.includes(para_id)) {
        this.selectedID = this.selectedID.filter((e) => e !== para_id);
      } else {
        this.selectedID.push(para_id);
      }
    },
    // async editForm(para_username){
    //     $('#modal-edit').modal('show');
    //     for(let e of this.listData){
    //         if(e.username == para_username){
    //             this.editData = {
    //                 USERNAME:e.username,
    //                 PASSWORD:'',
    //                 FNAME:e.name,
    //                 LNAME:e.lname,
    //                 ROLE:e.role_access_id,
    //                 COM_ID:e.company_id,
    //                 USER_IMG:e.user_image,
    //                 IS_ACTIVE:e.is_active
    //             }
    //             break
    //         }
    //     }

    // },

    uploadImageEdit(e) {
      let image = e.target.files[0];
      let FR = new FileReader();
      FR.onload = (ev) => {
        let getOringinalImg = ev.target.result;
        this.resizedataURLEdit(this, getOringinalImg, 100, 100);
      };
      FR.readAsDataURL(image);
    },
    resizedataURLEdit(_this, datas, wantedWidth, wantedHeight) {
      var img = document.createElement("img");
      img.onload = function () {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        var dataURI = canvas.toDataURL();
        console.log("dataURI:");
        console.log(dataURI);
      };
      img.src = datas;
    },
    viewpass() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
  computed: {
    getLocal: function () {
      return this.$store.getters["display/getLocal"];
    },
  },
};
</script>

<style scope>
.table-responsive {
  overflow-y: auto;
  max-height: 70vh;
}
.btn-action-cancel {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(245, 221, 37);
}
.btn-action-close {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(16, 169, 28);
}
.btn-action-delete {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(226, 80, 80);
}
.btn-action-view {
  background-color: rgb(3, 117, 238);
}
.btn-action {
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  color: white;
  margin: 0px 5px;
}
.img-size {
  max-height: 100px;
}
.textspace {
  letter-spacing: 5px;
}
.text-gray {
  color: rgb(186, 182, 182);
}
.text-yellow {
  color: rgb(223, 176, 23);
}
.text-green {
  color: rgb(99, 202, 14);
}
.text-blue {
  color: rgb(37, 108, 242);
}
</style>