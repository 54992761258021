<template>
  <div class="breadcrumb-main">
    <h4 class="text-capitalize breadcrumb-title">
      {{ t(options.pageKey, {}, { locale: getLocal }) }}
    </h4>
    <div class="breadcrumb-action justify-content-center flex-wrap">
      <div class="action-btn" v-if="options.isShowStartSearch || false">
        <a
          @click="options.startSearch"
          class="btn btn-sm btn-primary btn-add color-white"
          style="color: white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-search"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
          {{ t("search", {}, { locale: getLocal }) }}</a
        >
      </div>
      <div class="action-btn" v-if="options.isShowRefresh || false">
        <a
          @click="options.refresh"
          style="color: white"
          class="btn btn-sm btn-primary btn-add"
        >
          <i class="la la-rotate-right"></i>
          {{ t("refresh", {}, { locale: getLocal }) }}</a
        >
      </div>
      <div class="action-btn" v-if="options.isShowAddLottery || false">
        <a
          @click="$router.push({ name: 'AddLottery' })"
          class="btn btn-sm btn-primary btn-add"
          style="color: white"
        >
          <i class="la la-plus"></i>
          {{ t("addlotto", {}, { locale: getLocal }) }}</a
        >
      </div>
      <div class="action-btn" v-if="options.isShowAdd || false">
        <a
          @click="options.showModalAdd"
          class="btn btn-sm btn-primary btn-add"
          style="color: white"
        >
          <i class="la la-plus"></i>
          {{ t(options.buttonAddKey, {}, { locale: getLocal }) }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "Breadcrumb",
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  data: function () {
    return {};
  },
  props: ["options"],
  computed: {
    getLocal: function () {
      return this.$store.getters["display/getLocal"];
    },
  },
};
</script>

<style>
</style>
