<template>
    <div  class="modal fade" id="modal-image" role="dialog" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content  radius-xl">
                <div class="modal-header">
                    <h6 class="modal-title fw-500" id="staticBackdropLabel">{{ t('showimg',{},{locale:getLocal}) }}</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <img :src="srcImage" class="w-100" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
    name: "ModalImg",
    setup() {
        const {t, locale} = useI18n();
        return {t, locale};
    },
    data: function () {
        return {

        }
    },
    props: [
        "srcImage"
    ],
    computed: {
        getLocal:function(){
        return this.$store.getters['display/getLocal']
    }}
}
</script>

<style>

</style>