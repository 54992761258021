<template>
  <div class="d-flex justify-content-end mt-30">
      <div class="pagination-total-text">{{startData}}-{{endData}} of {{getCount}} items</div>

      <nav class="atbd-page ">
          <ul class="atbd-pagination d-flex">
              <li class="atbd-pagination__item">
                <span @click="minusmax" class="atbd-pagination__link pagination-control"><span class="la la-angle-left"></span><span class="la la-angle-left"></span></span>
                  <span @click="minus" class="atbd-pagination__link pagination-control"><span class="la la-angle-left"></span></span>
                  <span v-for="index in pageTotal"> 
                    <span v-if="(selectedPageData>index-4)&&(selectedPageData<index+4)"  @click="clickPage(index)" :class="`atbd-pagination__link ${selectedPageData ==index ?'active':''}`"><span class="page-number">{{index}}</span></span>
                  </span>
                  <!-- <a href="#" class="atbd-pagination__link active"><span class="page-number">2</span></a>
                  <a href="#" class="atbd-pagination__link"><span class="page-number">3</span></a>
                  <a href="#" class="atbd-pagination__link pagination-control"><span class="page-number">...</span></a>
                  <a href="#" class="atbd-pagination__link"><span class="page-number">12</span></a> -->
                  <span @click="plus" class="atbd-pagination__link pagination-control"><span class="la la-angle-right"></span></span>
                  <span @click="plusmax" class="atbd-pagination__link pagination-control"><span class="la la-angle-right"></span><span class="la la-angle-right"></span></span>
                  <a href="#" class="atbd-pagination__option"></a>
              </li>
              <li class="atbd-pagination__item">
                  <div class="paging-option">
                      <select v-model="limitData" name="page-number" class="page-selection">
                          <option value=1>1/page</option>
                          <option value=10>10/page</option>
                          <option value="40">40/page</option>
                          <option value="60">60/page</option>
                      </select>
                  </div>
              </li>
          </ul>
      </nav>


  </div>
</template>

<script>
export default {
  name: 'PaginationByConfirmLotto',
  props: [
    "Count"
  ],
  data: function () {
    return {
        limitData:1,
        startData:0,
        endData:0,
        offsetData:0,
        pageTotal:0,
        selectedPageData:1
    }
  },
  mounted() {
    this.start()
  },
  methods: {
    start(){
      let getCount = this.Count;
      if(getCount==0){
        this.startData = 0;
        this.endData = 0;
        this.selectedPageData=1;
        let PaginationReturn = {
          offset:0,
          limit:this.limitData,
          nowPage:1,
          pageTotal:1,
          startRow:0,
          endRow:0
        }
        this.$emit('PaginationReturnData',PaginationReturn)
        return
      }
      if(this.Count<this.limitData){
        this.offsetData = this.Count-1
        this.selectedPageData = 1
      }
      let getlimit = this.limitData;
      let pageTotal = Math.ceil(getCount/getlimit);
      this.pageTotal = pageTotal;
      this.startData = (this.selectedPageData*this.limitData)-this.limitData+1;
      this.endData = this.selectedPageData*this.limitData
      
      this.offsetData = this.startData-1;
      
      if(this.endData>getCount){
        this.endData = getCount
      }
      if(this.offsetData>this.Count){
        this.offsetData = this.Count-1
        this.startData = this.Count
      }
      let PaginationReturn = {
        offset:this.offsetData,
        limit:this.limitData,
        nowPage:this.selectedPageData,
        pageTotal:this.pageTotal,
        startRow:this.startData,
        endRow:this.endData
      }
      this.$emit('PaginationReturnData',PaginationReturn)
      // this.$emit('PaginationReturnData',PaginationReturn)
    },
    clickPage(para_SelectedPage){
      this.selectedPageData = para_SelectedPage
      this.start();
    },
    plus(){
      if(this.selectedPageData >=this.pageTotal){
        this.selectedPageData =this.pageTotal
        return
      }
      this.selectedPageData =this.selectedPageData+1
      this.start();
    },
    minus(){
      if(this.selectedPageData <=1){
        this.selectedPageData =1
        return
      }
      this.selectedPageData =this.selectedPageData-1
      this.start();
    },
    plusmax(){
      
      this.selectedPageData =this.pageTotal
      this.start();
    },
    minusmax(){
     
      this.selectedPageData = 1
      this.start();
    }
    
  },
  computed: {
    getCount:function(){
      return this.Count
    }
  },
  watch:{
    getCount:function(){
      // console.log(this.$refs.ListCartRef)
      // this.$refs.ListCartRef.isLoading= true;
      // console.log("getCount"+this.Count)
      this.start()
    },
    limitData:function(){
      // console.log(this.$refs.ListCartRef)
      // this.$refs.ListCartRef.isLoading= true;
      // console.log("limitData"+this.Count)
      this.selectedPageData=1
      this.start()
    }

  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
